import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import UpdateIcon from "@material-ui/icons/Update";
import FaceIcon from "@material-ui/icons/Face";
import { Link } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { connect } from "react-redux";
//import Typography from "@material-ui/core/Typography";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import NumberFormat from "react-number-format";

const useStyles = makeStyles({
 root: {
  width: "100%",
  maxWidth: "540px",
  position: "fixed",
  bottom: 0,
 },
 cart: {
  padding: "0px 10px",
  margin: "10px",
  background: "#e6dbcc",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  borderRadius: "15px",
  borderColor: "#e6dbcc",
  borderWidth: "2px",
 },
 proceedToPay: {
  background: "#ffffff",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
 },
 shoppingCart: { color: "#febd2e" },
 subtotal: { marginLeft: "10px", marginRight: "10px" },
 cartButton: {
  marginLeft: "auto",
  textTransform: "capitalize",
 },
 proceedToPayButton: {
  marginLeft: "auto",
  marginRight: "10px",
  textTransform: "capitalize",
 },
 space: {
  flexGrow: "1",
 },
});

function SimpleBottomNavigation(props) {
 const classes = useStyles();
 const [value, setValue] = React.useState(0);
 const { location } = props;

 useEffect(() => {
  switch (location.pathname) {
   case "/":
    setValue(0);
    break;
   case "/cart":
    setValue(1);
    break;
   case "/orders":
    setValue(2);
    break;
   case "/profile":
    setValue(3);
    break;
   default:
    setValue(1);
    return;
  }
 }, [location.pathname]);

 const calculateSubTotal = () => {
  var sub = 0;
  Object.keys(props.cart.items).map((el) => {
   return (sub += props?.cart?.items[el]?.count * props?.SKU[el]?.price);
  });
  return sub;
 };

 return (
  <div className={classes.root}>
   {props.cart.itemCount !== 0 && props.location.pathname === "/cart" ? (
    <Box className={classes.proceedToPay} flexDirection="row">
     <ShoppingCartIcon className={classes.shoppingCart} />
     {/* price x amount */}
     {/* <div className={classes.space} /> */}
     <p className={classes.subtotal}>
      Total{"  "}
      <NumberFormat
       value={calculateSubTotal() || 0}
       displayType={"text"}
       thousandSeparator={true}
       prefix={"Rp "}
       renderText={(value) => <b>{value}</b>}
      />
     </p>

     <Button
      variant="contained"
      color="primary"
      className={classes.proceedToPayButton}
      component={Link}
      to="/cart"
     >
      <b>Bungkus ({props.cart.itemCount})</b>
     </Button>
    </Box>
   ) : (
    <div />
   )}
   {props.cart.itemCount === 0 || props.location.pathname === "/cart" ? (
    <div />
   ) : (
    <Box className={classes.cart} flexDirection="row">
     <ShoppingCartIcon className={classes.shoppingCart} />
     {/* price x amount */}
     {/* <div className={classes.space} /> */}
     <p className={classes.subtotal}>
      Subtotal{"  "}
      <NumberFormat
       value={calculateSubTotal() || 0}
       displayType={"text"}
       thousandSeparator={true}
       prefix={"Rp "}
       renderText={(value) => <b>{value}</b>}
      />
     </p>

     <Button
      variant="contained"
      color="primary"
      className={classes.cartButton}
      component={Link}
      to="/cart"
     >
      Cek keranjang
     </Button>
    </Box>
   )}
   <BottomNavigation
    value={value}
    onChange={(event, newValue) => {
     setValue(newValue);
    }}
    showLabels={true}
   >
    <BottomNavigationAction
     label="Belanja"
     component={Link}
     to="/"
     icon={<HomeIcon />}
    />

    <BottomNavigationAction
     component={Link}
     to="/cart"
     label="Keranjang"
     icon={
      <Badge
       badgeContent={props.cart.itemCount}
       color="primary"
       invisible={props.cart.itemCount === 0}
      >
       <ShoppingBasketIcon fontSize="large" />
      </Badge>
     }
    />

    <BottomNavigationAction
     component={Link}
     to="/orders"
     label="Orderan"
     icon={<UpdateIcon />}
    />
    <BottomNavigationAction
     component={Link}
     to="/profile"
     label="Saya"
     icon={<FaceIcon />}
    />
   </BottomNavigation>
  </div>
 );
}

function mapStateToProps(state) {
 return {
  cart: state.cart,
  SKU: state.SKU,
 };
}

export default connect(mapStateToProps)(SimpleBottomNavigation);
