import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
//import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import "../css/App.css";

const useStyles = makeStyles((theme) => ({
 root: {
  maxWidth: "540px",
  right: "auto",
  backgroundColor: "#ffffff",
 },
 backButton: {
  marginRight: theme.spacing(1),
 },

 title: {
  flexGrow: 1,
 },
}));

function AppBarGeneric(props) {
 const { title, history } = props;
 const classes = useStyles();
 return (
  <AppBar className={classes.root}>
   <Toolbar>
    <div onClick={() => history.goBack()}>
     <IconButton
      edge="start"
      className={classes.backButton}
      color="inherit"
      aria-label="menu"
     >
      <ArrowBackIcon color="action" />
     </IconButton>
    </div>
    <Typography variant="h6" className={classes.title}>
     {title ?? "halaman"}
    </Typography>
   </Toolbar>
  </AppBar>
 );
}

export default AppBarGeneric;
