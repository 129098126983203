import app from "firebase/app";
import "@firebase/auth";
import "@firebase/firestore"; // 👈 If you're using firestore
import ReduxSagaFirebase from "redux-saga-firebase";

export const myFirebaseApp = app.initializeApp({
 apiKey: "AIzaSyB5QCo5w3xGsO59RR6ZNFMSNCOFjYrND1Y",
 authDomain: "bynem-project.firebaseapp.com",
 databaseURL: "https://bynem-project.firebaseio.com",
 projectId: "bynem-project",
 storageBucket: "bynem-project.appspot.com",
 messagingSenderId: "719026711108",
 appId: "1:719026711108:web:314e41647f9ab640da6a11",
 measurementId: "G-7X2WJTJZ06",
});

export const auth = myFirebaseApp.auth();

const reduxSagaFirebase = new ReduxSagaFirebase(myFirebaseApp);
export default reduxSagaFirebase;
