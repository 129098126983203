import React from "react";
import AppBarGeneric from "./AppBarGeneric";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/actionCreators";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
 base: {
  display: "flex",
  flexDirection: "column",
 },
 center: {
  marginTop: "35%",
  marginBottom: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
 },
 close: {
  top: 0,
 },
}));

function LoginScreen(props) {
 const classes = useStyles();
 const { me, history } = props;

 const login = () => {
  props.login("6282114271005");
 };

 return (
  <>
   <IconButton className={classes.close} onClick={() => history.goBack()}>
    <ArrowBackIcon />
   </IconButton>
   <div className={classes.base}>
    <div className={classes.center}>
     <Typography variant="h6">No HP Tuan / Nyonya</Typography>

     <img alt="cart empty" src="/assets/cartempty.png" />

     <Button variant="contained" color="primary" onClick={login}>
      Login dulu yuk
     </Button>
    </div>
   </div>
  </>
 );
}

function mapStateToProps(state) {
 return {
  me: state.me,
  SKU: state.SKU,
 };
}

function mapDispatchToProps(dispatch) {
 return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
