import produce from "immer";

function categories(state = {}, action) {
 return produce(state, (draft) => {
  switch (action.type) {
   case "FETCH_CATEGORIES_ALL_SUCCEEDED": {
    //console.log(action.categories);
    Object.keys(action.categories).map((key) => {
     return (draft[key] = action.categories[key]);
    });
    return draft;
    // console.log(action.categories);
    // action.categories.map((val) => {
    //  return draft.push(val);
    // });
    // Object.keys(action.news).map((key) => {
    //  return (draft[key] = action.news[key]);
    // });
    // break;
   }

   default:
    return state;
  }
 });
}

export default categories;
