import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/actionCreators";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Skeleton from "@material-ui/lab/Skeleton";
//import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Button from "@material-ui/core/Button";
import SKUCard from "./SKUCard";
//import Toolbar from "@material-ui/core/Toolbar";

import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

function HomeScreen(props) {
 const useStyles = makeStyles((theme) => ({
  rootGrid: {
   width: "100%",
   display: "flex",
   //    flexWrap: "wrap",
   justifyContent: "space-evenly",
   overflow: "hidden",
   textAlign: "center",
   //    border: "5px solid red",
  },
  gridList: {
   //    border: "5px solid blue",
   marginBottom: "5px",
  },

  IconButton: {
   width: "4rem",
   display: "relative",
   margin: "0 auto",
   //    border: "5px solid green",
  },

  IconName: {
   margin: "0 auto",
   fontSize: "0.85em",
  },

  label: {
   flexDirection: "column",
   textTransform: "none",
   lineHeight: "1.25",
  },
  socmed: {
   margin: "0 15px",
  },
  contactGroup: {
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
  },
  centeredLabel: {
   display: "flex",
   margin: "8px",
   alignItems: "center",
   justifyContent: "center",
  },
  normalColor: {
   color: "#000",
   textDecoration: "none",
  },
  contact: {
   margin: "0 2px",
  },
  buttonPrime: {
   margin: "10px",
  },
 }));

 //to get width and height of parent components
 const parentRef = useRef(null);
 const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
 // const [selectedNews, setSelectedNews] = useState(0);
 const {
  fetchNewsAll,
  news,
  fetchCategoriesAll,
  categories,
  SKU,
  fetchSKUAll,
 } = props;
 const history = useHistory();
 const classes = useStyles();

 useEffect(() => {
  //fetch news when homescreen is mounted/loaded
  if (Object.keys(news).length === 0) {
   fetchNewsAll();
  }

  //fetch categories when homescreen is mounted/loaded
  if (Object.keys(categories).length === 0) {
   fetchCategoriesAll();
  }

  //fetch items when homescreen is mounted/loaded
  //if (Object.keys(SKU).length === 0) {
  fetchSKUAll();
  //}
 }, [fetchNewsAll, fetchCategoriesAll, categories, fetchSKUAll, news]);

 useLayoutEffect(() => {
  if (parentRef.current) {
   setDimensions({
    width: parentRef.current.offsetWidth,
    height: parentRef.current.offsetHeight,
   });
  }
 }, []);

 const onClickItem = (index) => {
  history.push(`/news/${index}`);
 };

 return (
  <div className="content" ref={parentRef}>
   {Object.keys(news).length === 0 ? (
    <Skeleton
     variant="rect"
     animation="wave"
     width={dimensions.width}
     height={dimensions.width / 2.5}
    />
   ) : (
    <Carousel
     showThumbs={false}
     showStatus={false}
     showIndicators={true}
     infiniteLoop={true}
     autoPlay={true}
     swipeable={true}
     onClickItem={onClickItem}
    >
     {Object.keys(news).map(
      (key) =>
       (
        <img
         key={key}
         alt={news[key].title}
         height={dimensions.width / 2.5}
         src={news[key].image}
        />
       ) || (
        <Skeleton
         variant="rect"
         animation="wave"
         width={dimensions.width}
         height={dimensions.width / 2.5}
        />
       )
     )}
    </Carousel>
   )}
   <Typography variant="h5">
    Bynem baru soft launch nih, info singkat di mari dulu yah
   </Typography>

   <Button
    variant="contained"
    color="primary"
    className={classes.buttonPrime}
    onClick={() => {
     window.open("https://tokopedia.com/bynem", "_blank");
    }}
   >
    Link Tokopedia Bynem
   </Button>

   <Button
    variant="contained"
    color="primary"
    className={classes.buttonPrime}
    onClick={() => {
     window.open("https://instagram.com/bynem", "_blank");
    }}
   >
    Link Instagram Bynem
   </Button>

   <Button
    variant="contained"
    color="primary"
    className={classes.buttonPrime}
    onClick={() => {
     window.open("https://blog.bynem.com", "_blank");
    }}
   >
    Link Blog Bynem
   </Button>
   {/* <div className={classes.rootGrid}>
    <GridList cellHeight={100} cols={4}>
     {Object.keys(categories).length === 0
      ? [0, 1, 2, 3, 4, 5, 6, 7, 8].map((tile) => (
         <GridListTile
          key={tile}
          cols={tile.cols || 1}
          className={classes.gridList}
         >
          <div>
           <Skeleton
            variant="rect"
            animation="wave"
            className={classes.IconButton}
            width={80}
            height={80}
           />
          </div>
         </GridListTile>
        ))
      : Object.keys(categories).map((tile) => (
         <GridListTile
          key={tile}
          cols={tile.cols || 1}
          className={classes.gridList}
         >
          <div>
           <React.Fragment>
            <Button
             classes={{ root: classes.button, label: classes.label }}
             onClick={() => console.log(categories[tile].name)}
            >
             {tile ? (
              <img
               className={classes.IconButton}
               src={categories[tile].image}
               alt={categories[tile].name}
              />
             ) : (
              <Skeleton
               variant="rect"
               animation="wave"
               className={classes.IconButton}
               width={65}
               height={60}
              />
             )}

             <p className={classes.IconName}>
              {categories[tile].name || <Skeleton />}
             </p>
            </Button>
           </React.Fragment>
          </div>
         </GridListTile>
        ))}
    </GridList>
   </div> */}
   {/* <Typography variant="h5">Yang terbaru nih</Typography>
   
   <div className={classes.rootGrid}>
    <GridList cellHeight="auto" cols={2}>
     {Object.keys(SKU).map((tile) => (
      <GridListTile className={classes.gridList} key={tile}>
       <SKUCard dimensions={dimensions} SKU={SKU[tile]} SKUid={tile} />
      </GridListTile>
     ))}
    </GridList>
   </div> */}
   <center>
    <p>
     <small>
      &copy; 2020 Bynem. Seluruh hak Mba Inem dilindungi Yang Maha Kuasa.
     </small>
    </p>
    <a
     href="https://instagram.com/bynem"
     target="_blank"
     rel="noopener noreferrer"
    >
     <InstagramIcon className={classes.socmed} />
    </a>
    <a
     href="https://www.facebook.com/Bynem-215577336497409"
     target="_blank"
     rel="noopener noreferrer"
    >
     <FacebookIcon className={classes.socmed} />
    </a>
    {/* <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
     <YouTubeIcon className={classes.socmed} />
    </a> */}
    <div className={classes.contactGroup}>
     <a href="mailto:cs@bynem.com" className={classes.normalColor}>
      <div className={classes.centeredLabel}>
       <MailOutlineIcon className={classes.contact} />
       <span>
        <small>cs@bynem.com </small>
       </span>
      </div>
     </a>
     <a
      href="https://api.whatsapp.com/send?phone=6282210564240"
      target="_blank"
      rel="noopener noreferrer"
      className={classes.normalColor}
     >
      <div className={classes.centeredLabel}>
       <WhatsAppIcon className={classes.contact} />
       <span>
        <small>082210564240 </small>
       </span>
      </div>
     </a>
    </div>
   </center>
  </div>
 );
}

function mapStateToProps(state) {
 return {
  news: state.news,
  categories: state.categories,
  SKU: state.SKU,
 };
}

function mapDispatchToProps(dispatch) {
 return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
