import produce from "immer";
//models
// cart: {
//     subTotal: 0,
//     itemCount: 0,
//     items: [{ id: "8NLji8U0am12DHfoVaOz", count: 1 }],
//    },

// cart: {
//     subTotal: 0,
//     itemCount: 0,
//     items: { "8NLji8U0am12DHfoVaOz": {count:1} },
//    },

function cart(state = {}, action) {
 return produce(state, (draft) => {
  switch (action.type) {
   case "ADD_ITEM_TOCART": {
    var ret;
    if (state?.items[action.SKUid] && state?.items[action.SKUid]?.count !== 0) {
     //SKU already exists, increment count
     ret = { count: state.items[action.SKUid].count + 1 };
     draft.items[action.SKUid] = ret;
    } else {
     //SKU is not exist, add to cart
     ret = { count: 1 };
     draft["items"][action.SKUid] = ret;
    }
    draft.itemCount++;
    // //console.log(state?.items.map((e) => e.id).indexOf(action.SKUid));
    // if (state?.items.find((item) => item.id === action.SKUid)) {
    //  //item is already exist, increment its counter
    //  var i = state?.items.map((e) => e.id).indexOf(action.SKUid);
    //  const ret = { id: action.SKUid, count: state?.items[i].count + 1 };
    //  draft.items[i] = ret;
    // } else {
    //  //item is not found on items
    //  console.log("item not found, add 1");
    //  const ret = { id: action.SKUid, count: 1 };
    //  draft.items.push(ret);
    // }
    //draft[]
    return draft;
   }

   case "REMOVE_ITEM_FROMCART": {
    var rets;
    if (state?.items[action.SKUid] && state?.items[action.SKUid]?.count > 1) {
     //SKU already exists, decrement count
     rets = { count: state.items[action.SKUid].count - 1 };
     draft.items[action.SKUid] = rets;
    } else {
     //remove SKU from cart
     delete draft["items"][action.SKUid];
    }
    draft.itemCount--;
    return draft;
   }

   case "REMOVE_ALL_SPECIFIC_ITEM_FROMCART": {
    //remove SKU from cart
    const currentCount = state?.items[action.SKUid]?.count;
    draft.itemCount = draft.itemCount - currentCount;
    delete draft["items"][action.SKUid];

    return draft;
   }

   case "REMOVE_ALL_ITEM_FROMCART": {
    //console.log("remove all item");
    //remove SKU from cart
    draft.itemCount = 0;
    draft["items"] = {};

    return draft;
   }
   //return (draft[action.SKUid] += 1);
   // Object.keys(action.cart).map((key) => {
   //  return (draft[key] = action.cart[key]);
   // });

   // console.log(action.cart);
   // action.cart.map((val) => {
   //  return draft.push(val);
   // });
   // Object.keys(action.news).map((key) => {
   //  return (draft[key] = action.news[key]);
   // });
   // break;
   default:
    return state;
  }
 });
}

export default cart;
