import { call, put, takeLatest } from "redux-saga/effects";
import reduxSagaFirebase from "./FirebaseInit";
import * as actionCreators from "./actions/actionCreators";

import { myFirebaseApp } from "./FirebaseInit";

import app from "firebase/app";
import * as api from "./api/api";
import auth from "./FirebaseInit";

function* fetchNews(action) {
 try {
  const ref = myFirebaseApp.firestore().collection("news");
  const snapshot = yield call(
   reduxSagaFirebase.firestore.getCollection,
   ref.where("active", "==", true)
  );
  let news = [];
  snapshot.forEach((n) => {
   let temp = n.data();
   temp["id"] = n.id;
   news.push(temp);
   //news.add(n);
   //    news = {
   //     ...news,
   //     [n.id]: n.data(),
   //    };
  });
  yield put(actionCreators.FetchNewsAllSucceeded(news));
 } catch (e) {
  yield put(actionCreators.FetchNewsAllFailed(e.message));
 }
}

function* fetchCategories(action) {
 try {
  //console.log("fetchCategories Called");
  const ref = myFirebaseApp.firestore().collection("categories");
  const snapshot = yield call(
   reduxSagaFirebase.firestore.getCollection,
   ref.where("rank", "<=", 99)
  );
  let categories = {};
  snapshot.forEach((n) => {
   //    let temp = n.data();
   //    temp["id"] = n.id;
   //    news.push(temp);
   //categories.add(n);
   categories = {
    ...categories,
    [n.id]: n.data(),
   };
  });
  yield put(actionCreators.FetchCategoriesAllSucceeded(categories));
 } catch (e) {
  yield put(actionCreators.FetchCategoriesAllFailed(e.message));
 }
}

function* fetchSKU(action) {
 try {
  //const ref = myFirebaseApp.firestore().collection("SKU");
  //don't forget to alter query/filter here as per needed
  const snapshot = yield call(reduxSagaFirebase.firestore.getCollection, "SKU");
  let SKU = {};
  snapshot.forEach((n) => {
   //    let temp = n.data();
   //    temp["id"] = n.id;
   //    news.push(temp);
   //SKU.add(n);
   SKU = {
    ...SKU,
    [n.id]: n.data(),
   };
  });
  yield put(actionCreators.FetchSKUAllSucceeded(SKU));
 } catch (e) {
  yield put(actionCreators.FetchSKUAllFailed(e.message));
 }
}

function* fetchSKUIn(action) {
 try {
  //console.log("Fetch SKU in " + JSON.stringify(action));
  const ref = myFirebaseApp.firestore().collection("SKU");
  const snapshot = yield call(
   reduxSagaFirebase.firestore.getCollection,
   ref.where(app.firestore.FieldPath.documentId(), "in", action.SKUs)
  );

  let SKU = {};
  snapshot.forEach((n) => {
   //    let temp = n.data();
   //    temp["id"] = n.id;
   //    news.push(temp);
   //SKU.add(n);
   //console.log(n.data());
   SKU = {
    ...SKU,
    [n.id]: n.data(),
   };
  });
  yield put(actionCreators.FetchSKUAllSucceeded(SKU));
 } catch (e) {
  yield put(actionCreators.FetchSKUAllFailed(e.message));
 }
}

function* login(action) {
 try {
  console.log("login dari saga");
  const token = yield call(api.login, action.phoneNumber);
  console.log(token.data.data.token);
  const user = yield call(
   reduxSagaFirebase.auth.signInWithCustomToken,
   token.data.data.token
  );
  yield put(actionCreators.loginSucceeded(user));
 } catch (e) {
  console.log(e.message);
  yield put(actionCreators.FetchSKUAllFailed(e.message));
 }
}

function* mySaga() {
 yield takeLatest("FETCH_CATEGORIES_ALL", fetchCategories);
 yield takeLatest("FETCH_NEWS_ALL", fetchNews);
 yield takeLatest("FETCH_SKU_ALL", fetchSKU);
 yield takeLatest("FETCH_SKU_IN", fetchSKUIn);
 yield takeLatest("LOGIN", login);
}

export default mySaga;
