import React from "react";
import "./css/App.css";

import Toolbar from "@material-ui/core/Toolbar";
import SimpleBottomNavigation from "./components/BottomNavigationBar";
import TopAppBar from "./components/AppBar";

function App(props) {
 return (
  <React.Fragment>
   <div className="App">
    {/* for content padding */}
    <TopAppBar {...props} />

    <Toolbar />
    {props.children}
    <Toolbar />
    <SimpleBottomNavigation {...props} />
   </div>
  </React.Fragment>
 );
}

export default App;
