import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import news from "./news";
import categories from "./categories";
import SKU from "./SKU";
import cart from "./cart";
import me from "./me";

const rootReducer = combineReducers({
 news,
 categories,
 SKU,
 cart,
 me,
 routing: routerReducer,
});

export default rootReducer;
