import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "react-router-redux";
import rootReducer from "./reducers/index";
import mySaga from "./sagas";

import auth from "./FirebaseInit";

export const saveState = (state) => {
 try {
  const serializedState = JSON.stringify(state);
  localStorage.setItem("cart", serializedState);
 } catch {
  // ignore write errors
 }
};

export const loadState = () => {
 try {
  const serializedState = localStorage.getItem("cart");
  if (serializedState === null) {
   return { itemCount: 0, items: {} };
  }
  //console.log(JSON.parse(serializedState));
  return JSON.parse(serializedState);
 } catch (err) {
  return { itemCount: 0, items: {} };
 }
};

function thrott(func, timeFrame) {
 var lastTime = 0;
 return function () {
  var now = new Date();
  if (now - lastTime >= timeFrame) {
   func();
   lastTime = now;
  }
 };
}

//load cart from localstorage
const cart = loadState();

//load user from onAuthStateChanged
if (auth.currentUser) {
 console.log("user sudah logged in");
} else {
 console.log("user belum logged in");
}

const defaultState = {
 news: [],
 //   BAhvZrRwcfu: {
 //    description: "ini news bawaan",
 //    image: "https://placeimg.com/540/200/any",
 //    link: "https://google.com",
 //    title: "news bawaan",
 //   },
 categories: {},
 SKU: {},
 cart,
 me: {},
};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
 rootReducer,
 defaultState,
 applyMiddleware(sagaMiddleware)
);

store.subscribe(() => thrott(saveState(store.getState().cart), 1000));

export const history = syncHistoryWithStore(createBrowserHistory(), store);

sagaMiddleware.run(mySaga);

export default store;
