import produce from "immer";

function news(state = [], action) {
 return produce(state, (draft) => {
  switch (action.type) {
   case "FETCH_NEWS_ALL_SUCCEEDED": {
    //console.log(action.news);
    action.news.map((val) => {
     return draft.push(val);
    });
    return draft;
    // Object.keys(action.news).map((key) => {
    //  return (draft[key] = action.news[key]);
    // });
    // break;
   }

   default:
    return state;
  }
 });
}

export default news;
