import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/actionCreators";
import NumberFormat from "react-number-format";
import { deepOrange } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
//import Snackbar from "@material-ui/core/Snackbar";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const useStyles = makeStyles((theme) => ({
 root: {
  width: "100%",
  backgroundColor: theme.palette.background.paper,
 },
 inline: {
  display: "inline",
 },
 orange: {
  color: theme.palette.getContrastText(deepOrange[500]),
  backgroundColor: deepOrange[500],
 },
 listItemText: {
  width: "100%",
 },
 listItemSecondaryAction: {
  right: "5px",
 },
 subprice: {
  textAlign: "right",
  marginRight: "20px",
 },

 proceedToPay: {
  background: "#ffffff",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
 },
 button: {
  margin: theme.spacing(1),
  color: "#febd2e",
 },
 center: {
  marginTop: "35%",
  marginBottom: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
 },
 addMore: {
  width: "50%",
  marginTop: "15px",
  textTransform: "capitalize",
 },
 buttonBelowCart: {
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  borderColor: "primary",
 },
}));

function CartScreen(props) {
 const classes = useStyles();
 const { cart, SKU, fetchSKUIn } = props;

 const [open, setOpen] = React.useState(false);
 const [SKUHighlight, setSKUHighlight] = React.useState();

 const handleClickOpen = (item) => {
  setSKUHighlight(item);
  setOpen(true);
 };

 const handleClose = () => {
  setOpen(false);
 };

 const handleSureDelete = () => {
  if (SKUHighlight === "all") {
   props.RemoveAllItemFromCart();
  } else {
   props.RemoveAllSpecificItemFromCart(SKUHighlight);
  }

  setOpen(false);
 };

 useEffect(() => {
  //if cart is loaded first, fetch item in SKU not fetched yet
  //const diff = difference(Object.keys(cart.items), Object.keys(SKU));

  const initial = [Object.keys(cart.items), Object.keys(SKU)];
  const diff = initial.reduce((a, b) => a.filter((c) => !b.includes(c)));
  //console.log(diff);

  if (diff.length !== 0) {
   fetchSKUIn(diff);
  }
 }, [cart.items, SKU, fetchSKUIn]);

 return (
  <React.Fragment>
   {Object.keys(cart.items).length === 0 ? (
    //    this is hero image where cart is empty
    <div className={classes.center}>
     <Typography variant="h6">Keranjangnya masih kosong...</Typography>
     <img alt="cart empty" src="/assets/cartempty.png" />

     <Button variant="contained" color="primary" component={Link} to="/">
      Belanja Sekarang
     </Button>
    </div>
   ) : (
    <React.Fragment>
     <Box flexDirection="row" className={classes.proceedToPay}>
      <Typography variant="h5">List Belanja</Typography>
      <Button
       variant="outlined"
       color="primary"
       className={classes.button}
       startIcon={<DeleteIcon />}
       onClick={() => handleClickOpen("all")}
      >
       Semua
      </Button>
     </Box>
     <List className={classes.root}>
      {Object.keys(cart.items).map((item) => (
       <React.Fragment key={item}>
        <ListItem alignItems="flex-start">
         <ListItemAvatar>
          <Avatar
           className={classes.orange}
           alt={SKU[item]?.title || ""}
           src={SKU[item]?.image}
          >
           B
          </Avatar>
         </ListItemAvatar>
         <ListItemText
          className={classes.listItemText}
          primary={SKU[item]?.title}
          secondary={
           <React.Fragment>
            <NumberFormat
             value={SKU[item]?.price || 0}
             displayType={"text"}
             thousandSeparator={true}
             prefix={"Rp "}
             renderText={(value) => <span>{value}</span>}
            />{" "}
            / {SKU[item]?.unit}
            <IconButton
             onClick={() => handleClickOpen(item)}
             color="primary"
             aria-label="remove item from shopping cart"
            >
             <DeleteIcon />
            </IconButton>
           </React.Fragment>
          }
         />
         <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <div className={classes.subprice}>
           {" "}
           <NumberFormat
            value={SKU[item]?.price * cart.items[item].count || 0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp "}
            renderText={(value) => <span>{value}</span>}
           />{" "}
          </div>
          <IconButton
           onClick={() =>
            cart.items[item].count === 1
             ? handleClickOpen(item)
             : props.RemoveItemFromCart(item)
           }
           color="primary"
           aria-label="remove item from shopping cart"
          >
           <RemoveIcon />
          </IconButton>
          <Typography variant="body2" color="textSecondary" component="span">
           {"   "}
           {cart?.items[item]?.count || 0}
           {"   "}
          </Typography>
          <IconButton
           onClick={() => props.AddItemToCart(item)}
           color="primary"
           aria-label="add item to shopping cart"
          >
           <AddIcon />
          </IconButton>
         </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />
       </React.Fragment>
      ))}
     </List>
     <div className={classes.buttonBelowCart}>
      <Button
       variant="contained"
       color="primary"
       className={classes.addMore}
       component={Link}
       to="/"
      >
       <b> + Tambah barang lagi</b>
      </Button>
      <Button
       variant="outlined"
       color="primary"
       size="small"
       className={classes.addMore}
       onClick={() => {
        window.open(
         "https://api.whatsapp.com/send?phone=6282114271004&text=Nem,%20saya%20cari%20ini%20belum%20ada%20di%20katalog%20nih:",
         "_blank"
        );
        return null;
       }}
       //  component={() => {
       //   window.location.href =
       //    "https://api.whatsapp.com/send?phone=6282114271004";
       //   return null;
       //  }}
       startIcon={<WhatsAppIcon />}
      >
       <span> Yang dimau ndak ada? Kasi tau Inem!</span>
      </Button>
     </div>
     <Typography variant="h5">Kirim ke mana?</Typography>
    </React.Fragment>
   )}

   {/* Dialogbox for cart */}
   <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     {"Apakah Tuan/Nyonya yakin?"}
    </DialogTitle>
    <DialogContent>
     <DialogContentText id="alert-dialog-description">
      {SKUHighlight === "all" ? (
       <React.Fragment>
        {" "}
        <b>{`Semua item `}</b>
        {` akan dihapus dari list belanja`}
       </React.Fragment>
      ) : (
       <React.Fragment>
        <b>{`${SKU[SKUHighlight]?.title || " "}`}</b>
        {` akan dihapus dari list belanja`}
       </React.Fragment>
      )}
     </DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose} color="primary">
      Tidak jadi
     </Button>
     <Button onClick={() => handleSureDelete()} color="primary" autoFocus>
      Yakin
     </Button>
    </DialogActions>
   </Dialog>
  </React.Fragment>
 );
}

function mapStateToProps(state) {
 return {
  cart: state.cart,
  SKU: state.SKU,
 };
}

function mapDispatchToProps(dispatch) {
 return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CartScreen);
