import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import Base from "./Base";
import CartScreen from "./components/CartScreen";
import LoginScreen from "./components/LoginScreen";
import FavoriteScreen from "./components/FavoriteScreen";
import OrderScreen from "./components/OrderScreen";
import ProfileScreen from "./components/ProfileScreen";
import SearchScreen from "./components/SearchScreen";
import HomeScreen from "./components/HomeScreen";
import NewsDetailScreen from "./components/NewsDetailScreen";

import CssBaseline from "@material-ui/core/CssBaseline";
import "./css/index.css";

import store, { history } from "./store";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
 palette: {
  primary: {
   // main color orange bynem
   main: "#febd2e",
  },
  secondary: {
   // orange
   main: "#e6dbcc",
  },
 },
});

const router = (
 <Provider store={store}>
  <Router history={history}>
   <ThemeProvider theme={theme}>
    <Base>
     <Switch>
      <Route path="/favorite" component={FavoriteScreen}></Route>
      <Route path="/search" component={SearchScreen}></Route>
      <Route path="/news/:id" component={NewsDetailScreen}></Route>
      <Route path="/login" component={LoginScreen}></Route>
      <App>
       <Route exact path="/" component={HomeScreen}></Route>
       <Route path="/cart" component={CartScreen}></Route>
       <Route path="/orders" component={OrderScreen}></Route>
       <Route path="/profile" component={ProfileScreen}></Route>
      </App>
     </Switch>
    </Base>
   </ThemeProvider>
  </Router>
 </Provider>
);

ReactDOM.render(
 <React.StrictMode>
  <CssBaseline />
  {router}
 </React.StrictMode>,
 document.getElementById("root")
);

if (module.hot) {
 module.hot.accept("./App", () => {
  const NextApp = require("./App").default;
  ReactDOM.render(<NextApp />, document.getElementById("root"));
 });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
