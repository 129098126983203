import produce from "immer";

function SKU(state = {}, action) {
 return produce(state, (draft) => {
  switch (action.type) {
   case "FETCH_SKU_ALL_SUCCEEDED": {
    //console.log(action.SKU);
    Object.keys(action.SKU).map((key) => {
     return (draft[key] = action.SKU[key]);
    });
    return draft;
    // console.log(action.SKU);
    // action.SKU.map((val) => {
    //  return draft.push(val);
    // });
    // Object.keys(action.news).map((key) => {
    //  return (draft[key] = action.news[key]);
    // });
    // break;
   }

   default:
    return state;
  }
 });
}

export default SKU;
