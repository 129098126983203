import React from "react";

function OrderScreen() {
 return (
  <div className="content">
   <p>I'm oders screen</p>
  </div>
 );
}

export default OrderScreen;
