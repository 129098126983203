import React from "react";
import AppBarGeneric from "./AppBarGeneric";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/actionCreators";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
 base: {
  display: "flex",
  flexDirection: "column",
 },
 center: {
  marginTop: "35%",
  marginBottom: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
 },
 image: {
  width: "150px",
  height: "150px",
  margin: "10px auto",
 },
}));

function FavoriteScreen(props) {
 const classes = useStyles();
 const { me } = props;

 return (
  <div className={classes.base}>
   <AppBarGeneric title={"Catetan favorit"} {...props} />
   {Object.keys(me).length === 0 ? (
    <div className={classes.center}>
     <Typography variant="h6">
      Tuan / Nyonya belum login nih.
      <br /> Catetannya saya simpen di awan...
     </Typography>

     <img className={classes.image} alt="cart empty" src="/assets/inem.png" />

     <Button variant="contained" color="primary" component={Link} to="/login">
      Login dulu yuk
     </Button>
    </div>
   ) : (
    <div>test</div>
   )}
  </div>
 );
}

function mapStateToProps(state) {
 return {
  me: state.me,
  SKU: state.SKU,
 };
}

function mapDispatchToProps(dispatch) {
 return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteScreen);
