import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import InputBase from "@material-ui/core/InputBase";
//import { Link } from "react-router-dom";

import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import "../css/App.css";

const useStyles = makeStyles((theme) => ({
 root: {
  maxWidth: "540px",
  right: "auto",
 },
 menuButton: {
  marginRight: theme.spacing(1),
 },

 search: {
  backgroundColor: "#ffffff",
  flexGrow: 5,
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
   marginLeft: theme.spacing(1),
   width: "auto",
  },
 },
 searchIcon: {
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
 },
 inputRoot: {
  color: "inherit",
 },
 inputInput: {
  padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon
  paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  transition: theme.transitions.create("width"),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
   width: "20ch",
   "&:focus": {
    width: "30ch",
   },
  },
 },
}));

function SearchScreen(props) {
 const classes = useStyles();
 const { history } = props;
 return (
  <AppBar className={classes.root}>
   <Toolbar>
    <div onClick={() => history.goBack()}>
     <IconButton
      edge="start"
      className={classes.menuButton}
      color="inherit"
      aria-label="menu"
     >
      <ArrowBackIcon color="action" />
     </IconButton>
    </div>
    <div className={classes.search}>
     <div className={classes.searchIcon}>
      <SearchIcon />
     </div>
     <InputBase
      autoFocus={true}
      placeholder="bawang putih"
      classes={{
       root: classes.inputRoot,
       input: classes.inputInput,
      }}
      inputProps={{ "aria-label": "search" }}
     />
    </div>
   </Toolbar>
  </AppBar>
 );
}

export default SearchScreen;
