import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import FavoriteIcon from "@material-ui/icons/Favorite";

import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import InputBase from "@material-ui/core/InputBase";
import { Link } from "react-router-dom";

//css for Top AppBar only
const useStyles = makeStyles((theme) => ({
 root: {
  maxWidth: "540px",
  right: "auto",
 },
 menuButton: {
  marginRight: theme.spacing(1),
 },
 favoriteButton: {
  marginLeft: theme.spacing(1),
 },
 title: {
  flexGrow: 2,
 },
 logo: {
  width: "32px",
  height: "32px",
 },
 search: {
  flexGrow: 1,
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: fade(theme.palette.common.white, 0.85),
  "&:hover": {
   backgroundColor: fade(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
   marginLeft: theme.spacing(1),
   width: "auto",
  },
 },
 searchIcon: {
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
 },
 inputRoot: {
  color: "#000000",
 },
 inputInput: {
  padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon
  paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  transition: theme.transitions.create("width"),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
   width: "20ch",
   "&:focus": {
    width: "30ch",
   },
  },
 },
}));

function HideOnScroll(props) {
 const { children, window } = props;
 const classes = useStyles();
 // Note that you normally won't need to set the window ref as useScrollTrigger
 // will default to window.
 const trigger = useScrollTrigger({ target: window });

 return (
  <Slide appear={false} direction="down" in={!trigger} className={classes.root}>
   {children}
  </Slide>
 );
}

function SearchBar(props) {
 const classes = useStyles();
 return (
  <div className={classes.search}>
   <Link to="/search" style={{ textDecoration: "none" }}>
    <div className={classes.searchIcon}>
     <SearchIcon color="action" />
    </div>

    <InputBase
     placeholder="Inem bantu cari ya?"
     classes={{
      root: classes.inputRoot,
      input: classes.inputInput,
     }}
     inputProps={{ "aria-label": "search" }}
     //  onMouseEnter={() => console.log("on focus")}
    />
   </Link>
  </div>
 );
}

function Title(props) {
 const classes = useStyles();
 return (
  <Typography variant="h6" className={classes.title}>
   {props.location.pathname === "/cart"
    ? "Keranjang Belanja"
    : props.location.pathname === "/orders"
    ? "Orderan"
    : props.location.pathname === "/profile"
    ? "Siapa saya"
    : "Lain-lain"}
  </Typography>
 );
}

export default function TopAppBar(props) {
 const classes = useStyles();
 const { location } = props;
 return (
  <HideOnScroll {...props}>
   <AppBar>
    <Toolbar>
     <Link to="/">
      <IconButton
       edge="start"
       className={classes.menuButton}
       color="inherit"
       aria-label="menu"
      >
       <img src="/assets/inem.png" className={classes.logo} alt="" />
      </IconButton>
     </Link>
     <Typography variant="h6" className={classes.title}>
      Bynem
     </Typography>
     {/* {location.pathname === "/" ? <SearchBar /> : <Title {...props} />}

     <IconButton
      component={Link}
      to="/favorite"
      edge="end"
      className={classes.favoriteButton}
      color="inherit"
      aria-label="menu"
     >
      <FavoriteIcon />
     </IconButton> */}
    </Toolbar>
   </AppBar>
  </HideOnScroll>
 );
}
