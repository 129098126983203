import React from "react";
import webShare from "react-web-share-api";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from "@material-ui/icons/Share";

const Button = ({ share, isSupported }) =>
 isSupported ? (
  //   <button onClick={share}>Share now!</button>
  <IconButton
   //    className={classes.share}
   onClick={share}
  >
   <ShareIcon />
  </IconButton>
 ) : (
  <></>
 );

export default webShare()(Button);
