import React, { useEffect } from "react";
import "./css/index.css";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./actions/actionCreators";

function Base(props) {
 useEffect(() => {
  //loading effect before react is loading
  const ele = document.getElementById("ipl-progress-indicator");
  if (ele) {
   // fade out
   ele.classList.add("available");
   setTimeout(() => {
    // remove from DOM
    ele.outerHTML = "";
   }, 2000);
  }

  console.log("change");
  //localStorage;
 }, []);

 return (
  <React.Fragment>
   <div className="base">{props.children}</div>
  </React.Fragment>
 );
}

function mapStateToProps(state) {
 return {
  news: state.news,
 };
}

function mapDispatchToProps(dispatch) {
 return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Base);
