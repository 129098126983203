export function fetchNewsAll() {
 return {
  type: "FETCH_NEWS_ALL",
 };
}

export function FetchNewsAllSucceeded(news) {
 return {
  type: "FETCH_NEWS_ALL_SUCCEEDED",
  news,
 };
}

export function FetchNewsAllFailed(message) {
 return {
  type: "FETCH_NEWS_ALL_FAILED",
  message,
 };
}

export function fetchCategoriesAll() {
 return {
  type: "FETCH_CATEGORIES_ALL",
 };
}

export function FetchCategoriesAllSucceeded(categories) {
 return {
  type: "FETCH_CATEGORIES_ALL_SUCCEEDED",
  categories,
 };
}

export function FetchCategoriesAllFailed(message) {
 return {
  type: "FETCH_CATEGORIES_ALL_FAILED",
  message,
 };
}

export function fetchSKUAll() {
 return {
  type: "FETCH_SKU_ALL",
 };
}

export function fetchSKUIn(SKUs) {
 return {
  type: "FETCH_SKU_IN",
  SKUs,
 };
}

export function FetchSKUAllSucceeded(SKU) {
 return {
  type: "FETCH_SKU_ALL_SUCCEEDED",
  SKU,
 };
}

export function FetchSKUAllFailed(message) {
 return {
  type: "FETCH_SKU_ALL_FAILED",
  message,
 };
}

export function AddItemToCart(SKUid) {
 return {
  type: "ADD_ITEM_TOCART",
  SKUid,
 };
}

export function RemoveItemFromCart(SKUid) {
 return {
  type: "REMOVE_ITEM_FROMCART",
  SKUid,
 };
}

export function RemoveAllSpecificItemFromCart(SKUid) {
 return {
  type: "REMOVE_ALL_SPECIFIC_ITEM_FROMCART",
  SKUid,
 };
}

export function RemoveAllItemFromCart() {
 return {
  type: "REMOVE_ALL_ITEM_FROMCART",
 };
}

export function login(phoneNumber) {
 return {
  type: "LOGIN",
  phoneNumber,
 };
}

export function loginSucceeded(token) {
 return {
  type: "LOGIN_SUCCESS",
  token,
 };
}

export function loginFailed(message) {
 return {
  type: "LOGIN_FAILED",
  message,
 };
}
