import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AppBarGeneric from "./AppBarGeneric";
import { connect } from "react-redux";
import Iframe from "react-iframe";
import { Toolbar } from "@material-ui/core";
import Spinner from "react-spinkit";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
 loading: {
  marginTop: "80%",
  textAlign: "center",
  //   border: "5px solid red",
 },
 title: {
  textAlign: "center",
 },
 iframe: {
  maxWidth: "540px",
  border: "10px solid red",
  marginTop: "60px",
 },
}));

function NewsDetailScreen(props) {
 const [loading, setLoading] = useState(true);
 const { news } = props;
 const { id } = useParams();
 const classes = useStyles();

 const hideSpinner = () => {
  setLoading(false);
 };

 return (
  <div className="content">
   <AppBarGeneric title={`${news[id]?.title ?? news}`} {...props} />
   <Toolbar />
   {loading ? (
    <div>
     <Spinner
      className={classes.loading}
      name="three-bounce"
      color="#febd2e"
      fadeIn="none"
     />
     <h3 className={classes.title}>Sebentar Inem muat artikelnya ya...</h3>
    </div>
   ) : null}
   <Iframe
    url={`${news[id]?.link ?? "https://samueldav.id"}`}
    width="100%"
    height={window.innerHeight}
    id="myId"
    loading="auto"
    onLoad={hideSpinner}
    className={classes.iframe}
    display="initial"
    position="absolute"
    frameBorder="0"
   />
  </div>
 );
}

function mapStateToProps(state) {
 return {
  news: state.news,
 };
}

export default connect(mapStateToProps)(NewsDetailScreen);
