import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/actionCreators";
import NumberFormat from "react-number-format";
import ShareButton from "./ShareButton";

const useStyles = makeStyles({
 root: {},
 media: {
  height: 140,
 },
 cardActions: {
  flexDirection: "row",
  justifyContent: "space-between",
 },
 buttonAdd: {
  width: "100%",
  height: "2rem",
 },
 //  favorite: {
 //   //position: "absolute",
 //   top: 0,
 //   right: 0,
 //  },
 //  share: {
 //   position: "absolute",
 //   top: 50,
 //   right: 0,
 //   color: "primary",
 //  },
});

function SKUCard(props) {
 const { dimensions, SKU, SKUid } = props;
 const classes = useStyles();

 return (
  <Card className={classes.root} width={dimensions.width / 2 - 50}>
   <CardActionArea onClick={() => console.log("card tapped")}>
    <CardMedia
     className={classes.media}
     image={SKU.image}
     title="Contemplative Reptile"
    ></CardMedia>
    <CardActions disableSpacing></CardActions>

    <CardContent>
     <span>
      <Typography gutterBottom variant="h5" component="h2">
       {SKU.title}
      </Typography>
     </span>
     <Typography variant="body2" color="textSecondary" component="p">
      {SKU.subtitle}
     </Typography>
     <Typography variant="body2" color="textSecondary" component="p">
      <NumberFormat
       value={SKU.price || 0}
       displayType={"text"}
       thousandSeparator={true}
       prefix={"Rp "}
       renderText={(value) => <span>{value}</span>}
      />{" "}
      / {SKU.unit}
     </Typography>
    </CardContent>
   </CardActionArea>

   <ShareButton
    className={classes.share}
    config={{
     params: {
      title: `Beli ${SKU.title} di Bynem.com`,
      text: `Beli ${SKU.title} di Bynem.com, paling manteb`,
      url: `https://bynem.com/products/${SKUid}`,
     },
     /* tslint:disable-next-line:no-console */
     onShareSuccess: () => console.log("Success"),
     /* tslint:disable-next-line:no-console */
     onShareError: (error) => console.log("error", error),
    }}
   />
   <IconButton
    className={classes.favorite}
    onClick={() => console.log("favorite click")}
   >
    <FavoriteIcon />
   </IconButton>
   {Object.keys(props.cart.items).length === 0 || !props?.cart?.items[SKUid] ? (
    <Button
     className={classes.buttonAdd}
     color="primary"
     variant="contained"
     onClick={() => props.AddItemToCart(SKUid)}
    >
     <Typography variant="body2" color="textSecondary" component="p">
      Beli
     </Typography>
    </Button>
   ) : (
    <CardActions className={classes.cardActions}>
     <IconButton
      onClick={() => props.RemoveItemFromCart(SKUid)}
      color="primary"
      aria-label="remove item from shopping cart"
     >
      <RemoveIcon />
     </IconButton>
     <Typography variant="body2" color="textSecondary" component="p">
      {props?.cart?.items[SKUid]?.count || 0}
     </Typography>
     <IconButton
      onClick={() => props.AddItemToCart(SKUid)}
      color="primary"
      aria-label="add item to shopping cart"
     >
      <AddIcon />
     </IconButton>
    </CardActions>
   )}
  </Card>
 );
}

function mapStateToProps(state) {
 return {
  cart: state.cart,
 };
}

function mapDispatchToProps(dispatch) {
 return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SKUCard);
