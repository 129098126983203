import React from "react";

function ProfileScreen() {
 return (
  <div className="content">
   <p>I'm profile screen</p>{" "}
  </div>
 );
}

export default ProfileScreen;
